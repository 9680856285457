import { Injectable } from '@angular/core';
import { IRole } from '../models/role';
import { Feature } from '../models/feature';
import { SubFeature } from '../models/sub-feature';

@Injectable({
  providedIn: 'root',
})
export class RolesMapper {
  public mapRoleResponseToRole(
    roleResponse: object
  ): IRole {
    if (!roleResponse) {
      return <IRole>{};
    }
    const data = roleResponse['role'] ? roleResponse['role'] : roleResponse;
    const role = <IRole>{
      id: data['ROLE_ID'],
      name: data['NAME'],
      description: data['DESCRIPTION'],
      isProtected: data['IS_PROTECTED'] === 'Y',
      levelId: data['LEVEL_ID'],
      levelName: data['LEVEL_NAME'],
      features: []
    };
    if (roleResponse['features'] && roleResponse['features'].length > 0) {
      role.features = roleResponse['features'].map((feature: object) => this.mapFeatureResponseToFeature(feature));
    }

    return role;
  }

  public mapRolesResponseToRoles(
    rolesResponse: object[]
  ): IRole[] {
    if (!rolesResponse || rolesResponse.length === 0) {
      return [];
    }
    return rolesResponse.map((roleResponse: object) => this.mapRoleResponseToRole(roleResponse));
  }

  public mapFeatureResponseToFeature(
    featureResponse: object
  ): Feature {
    const feature = <Feature>{
      id: featureResponse['FEATURE_ID'],
      name: featureResponse['NAME'],
      description: featureResponse['DESCRIPTION'],
      children: [],
    };
    if (featureResponse['SUBFEATURES'] && featureResponse['SUBFEATURES'].length > 0) {
      feature.children = featureResponse['SUBFEATURES'].map((subFeature: object) => this.mapSubFeatureResponseToSubFeature(subFeature));
    }

    return feature;
  }

  public mapSubFeatureResponseToSubFeature(
    subFeatureResponse: object
  ): SubFeature {
    const subFeature = <SubFeature>{
      id: subFeatureResponse['SUBFEATURE_ID'],
      name: subFeatureResponse['NAME'],
      description: subFeatureResponse['DESCRIPTION'],
      isEnabled: subFeatureResponse['IS_ENABLED'],
    };

    return subFeature;
  }
}
