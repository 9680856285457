import { Injectable } from '@angular/core';
import {
  HttpClient, HttpErrorResponse, HttpHeaders, HttpParams 
} from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY } from '@angular/cdk/overlay/overlay-directives';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { mockUserFeatures } from '../http-interceptors/mock-data/user-data.mock';

@Injectable({
  providedIn: 'root',
})
export class CsaBackEndService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token',
    }),
  };

  private createSurveyUrl = `${environment.backEndUrl}/createSurvey`;

  private uploadPhotosUrl = `${environment.backEndUrl}/storage/upload/files`;

  private removePhotoUrl = `${environment.backEndUrl}/storage/remove/file/`;

  private getPhotosUrl = `${environment.backEndUrl}/storage/generateUrl`;

  private surveysUrl = `${environment.backEndUrl}/survey/list`;

  private surveyResponseUrl = `${environment.backEndUrl}/survey-response/list`;

  private surveyByIDUrl = `${environment.backEndUrl}/response/`;

  private adminSurveyByIDUrl = `${environment.backEndUrl}/survey/`;

  private updateSurveyUrl = `${environment.backEndUrl}/survey/update`;

  private userSurveyStateUrl = `${environment.backEndUrl}/userSurveyState`;

  private searchSurveysUrl = `${environment.backEndUrl}/survey/search/`;

  private searchSectionHeadingsUrl = `${environment.backEndUrl}/section-heading/search`;

  private searchControlNamesUrl = `${environment.backEndUrl}/control-name/search`;

  private listEvidenceUrl = `${environment.backEndUrl}/evidence/list`;

  private templatesUrl = `${environment.backEndUrl}/templates`;

  private updateTemplateUrl = `${environment.backEndUrl}/template/update`;

  private uploadTemplatesUrl = `${environment.backEndUrl}/uploadTemplate/`;

  private getTemplatesDriveUrl = `${environment.backEndUrl}/templates/gdrive/url`;

  private rolesUrl = `${environment.backEndUrl}/roles`;

  private userUrl = `${environment.backEndUrl}/user`;

  private createActionUrl = `${environment.backEndUrl}/actions/create`;

  private updateActionUrl = `${environment.backEndUrl}/actions/update`;

  private saveResponseUrl = `${environment.backEndUrl}/response/update/all`;

  private eitbEmailTriggerUrl = `${environment.backEndUrl}/eitbEmailTrigger`;

  private mrrActivateEmailTriggerUrl = `${environment.backEndUrl}/emailTrigger`;

  private getReportsUrl = `${environment.backEndUrl}/reports/list`;

  private drillDownUrl = `${environment.backEndUrl}/survey/drillDown/`;

  private saveQuestionUrl = `${environment.backEndUrl}/response/update/question`;

  private actionListUrl = `${environment.backEndUrl}/actions/list`;

  private getActionUrl = `${environment.backEndUrl}/actions/get/`;

  private autoSaveNewActionUrl = `${environment.backEndUrl}/response/autoSaveAction/push`;

  private autoSaveUpdateActionUrl = `${environment.backEndUrl}/response/autoSaveAction/update`;

  private autoSaveDeleteActionUrl = `${environment.backEndUrl}/response/autoSaveAction/delete`;

  private createOnTheFlySurveyUrl = `${environment.backEndUrl}/createSurveyResponse/adhoc`;

  private storeUrl = `${environment.backEndUrl}/store`;

  private storeListUrl = `${environment.backEndUrl}/store/list`;

  private updateStoreUrl = `${environment.backEndUrl}/store/`;

  private orgStructureUrl = `${environment.backEndUrl}/orgStructure/`;

  private nextLevelUrl = `${environment.backEndUrl}/orgStructure/belowLevel`;

  private storeListbelowLevelUrl = `${environment.backEndUrl}/store/list/belowLevel`;

  private getUserAccessListUrl = `${environment.backEndUrl}/users/list`;

  private updateUserUrl = `${environment.backEndUrl}/users/`;

  private test401Url = `${environment.backEndUrl}/test_401`;

  private storeMRRDraft = `${environment.backEndUrl}/mrrDraft`;

  private updateMRRDraft = `${environment.backEndUrl}/mrrDraft/update`;

  private deleteMRRDraft = `${environment.backEndUrl}/mrrDraft/delete`;

  private updateMRRFinalReport = `${environment.backEndUrl}/updateMRRFinalReport`;

  private signOff = `${environment.backEndUrl}/signOff`;

  constructor(private httpClient: HttpClient, private cookieService: CookieService) { }

  /**
   * Attaches a prefix to the fields specified in fieldsToPrefix for the purpose of bypassing firewall policies.
   * 
   * The names of the field in the array should be provided without the prefix.
   *
   * Background:
   * Any field with the prefix attached is exempted from various akamai policy rules. Akamai policy rules are part of a firewall that sits between our backend application and the outside world.
   * The problem we were running into was that some of our user inputted data was triggering false positives in the policy, and being rejected - causing application errors.
   *
   * To avoid this, the prefix was introduced and an exemption was made for all fields with the prefix (via a regex pattern). The backend has its own utility function to remove this prefix.
   * Before using this function, you must ensure that the fields you are exempting are not used in the application in a way that is susceptible to SQL injection.
   * 
   * @param requestJSONPayload - Request JSON to attach the prefixes to.
   * @param fieldsToPrefix - An array of strings, containing the names of the fields to attach the prefix to.
   * @returns JSON with prefixes attached to specified fields.
   */
  attachFirewallBypassPrefixes(requestJSONPayload: object, fieldsToPrefix: string[]): object {
    const safeKeyPrefix = 'safeContent_'; // this prefix string also exists in the backend and akamai dashboard. DO NOT CHANGE.
    let payloadAsString: string = JSON.stringify(requestJSONPayload); // adding the prefixes is much easier when the JSON is a string.

    // add the prefix for each provided field.
    fieldsToPrefix.forEach((fieldName) => {
      payloadAsString = payloadAsString.split(`"${fieldName}":`).join(`"${safeKeyPrefix}${fieldName}":`);
    });

    return JSON.parse(payloadAsString);
  }

  setToken(token: string): void {
    this.httpOptions.headers = this.httpOptions.headers.set(
      'Authorization',
      token
    );
  }

  // non use
  setContentType(type: string): void {
    this.httpOptions.headers = this.httpOptions.headers.set(
      'Content-Type',
      type
    );
  }

  getOnTheFlySurveys(
    group: string,
    status: string,
    index: string,
    count: string,
    type: string
  ): Observable<object[]> {
    let fullUrl = '';
    if (status == '') {
      fullUrl = `${this.surveyResponseUrl
        + index
        + count
      }?division=${
        group
      }&type=${
        type}`;
    } else {
      fullUrl = `${this.surveyResponseUrl
        + index
        + count
      }?status=${
        status
      }&division=${
        group
      }&type=${
        type}`;
    }
    return this.httpClient.get<object[]>(fullUrl);
  }
  // landing, recurring

  getAboveStoreSurveys(
    userGrade: string,
    queryString: string,
    index: string,
    count: string
  ): Observable<object[]> {
    const fullUrl = this.surveysUrl + userGrade + index + count + queryString;
    return this.httpClient.get<object[]>(fullUrl);
  }

  // getDrillDownInfo(searchString): Observable<object[]> {
  //   let httpHeaders = new HttpHeaders({
  //     skip: "true",
  //   });
  //   let options = {
  //     headers: httpHeaders,
  //   };
  //   let fullUrl = this.drillDownUrl + searchString;
  //   return this.httpClient.get<object[]>(fullUrl, options);
  // }
  // mrr , survey
  // getSurveyById(id: string): Observable<object[]> {
  //   let fullUrl = this.surveyByIDUrl + id;
  //   return this.httpClient.get<object[]>(fullUrl);
  // }

  // mrr , survey ,landing
  // getAboveStoreSurveyID(id: string, filter: string): Observable<object[]> {
  //   let fullUrl = this.adminSurveyByIDUrl + id + filter;
  //   let httpHeaders = new HttpHeaders({
  //     skip: "true",
  //   });
  //   let options = {
  //     headers: httpHeaders,
  //   };
  //   return this.httpClient.get<object[]>(fullUrl, options);
  // }
  // mrr , survey ,landing,recurring
  //  getAdminSurveyById(id: string): Observable<object[]> {
  //     let fullUrl = this.adminSurveyByIDUrl + id;
  //     return this.httpClient.get<object[]>(fullUrl);
  //   }
  // non use
  getUserSurveyState(): Observable<object[]> {
    return this.httpClient.get<object[]>(this.userSurveyStateUrl);
  }

  // suvery.landing
  // getTemplates(UrlParams: string): Observable<object[]> {
  //   let fullUrl = this.templatesUrl + UrlParams;
  //   return this.httpClient.get<object[]>(fullUrl);
  // }
  // landing page
  // getTemplateDetails(templateID: string): Observable<object[]> {
  //   let fullUrl = this.templatesUrl + "/" + templateID;
  //   return this.httpClient.get<object[]>(fullUrl);
  // }
  // non use
  getActionsPerQuestion(
    index: string,
    count: string,
    storeNumber: string,
    survey: string,
    question: string
  ): Observable<object[]> {
    const fullUrl = `${this.actionListUrl
      + index
      + count
    }?storeID=${
      storeNumber
    }&surveyID=${
      survey
    }&questionID=${
      question}`;
    return this.httpClient.get<object[]>(fullUrl);
  }
  // survey, landing
  // getReportsList(
  //   index: string,
  //   count: string,
  //   role: string
  // ): Observable<object[]> {
  //   let fullUrl = this.getReportsUrl + index + count + role;
  //   return this.httpClient.get<object[]>(fullUrl);
  // }
  // landing,survey,mrr
  // getActionList(
  //   index: string,
  //   count: string,
  //   status: string
  // ): Observable<object[]> {
  //   let fullUrl = "";
  //   fullUrl = this.actionListUrl + index + count + status;
  //   return this.httpClient.get<object[]>(fullUrl);
  // }
  // landing,mrr,survey
  // getAction(id: string): Observable<object[]> {
  //   let fullUrl = this.getActionUrl + id;
  //   return this.httpClient.get<object[]>(fullUrl);
  // }
  // landing,mrr,survey
  // getPhotos(photos: string): Observable<object[]> {
  //   let fullUrl = this.getPhotosUrl + photos;
  //   return this.httpClient.get<object[]>(fullUrl);
  // }
  // landing,mrr,survey
  // removePhoto(
  //   file: string,
  //   data: object,
  //   responseID: string,
  //   question_id: string
  // ): Observable<object> {
  //   let fullUrl =
  //     this.removePhotoUrl + file + "/" + responseID + "/" + question_id;
  //   return this.httpClient.delete<object>(fullUrl, data);
  // }

  // removeDoc(
  //   file: string,
  //   data: object,
  //   responseID: string,
  //   fileTag: string
  // ): Observable<object> {
  //   let fullUrl =
  //     this.removePhotoUrl + file + "/" + responseID + "/" + fileTag;
  //   return this.httpClient.delete<object>(fullUrl, data);
  // }
  // mrr and store survey
  // saveQuestion(data: object): Observable<object> {
  //   let httpHeaders = new HttpHeaders({
  //     "Content-Type": "application/json",
  //     "Cache-Control": "no-cache",
  //     skip: "true",
  //   });
  //   let options = {
  //     headers: httpHeaders,
  //   };
  //   let fullUrl = this.saveQuestionUrl;
  //   return this.httpClient.put<object>(fullUrl, data, options);
  // }
  // mrr and storesurvey
  // autoSaveAction(data: object, status: string): Observable<object> {
  //     let httpHeaders = new HttpHeaders({
  //       "Content-Type": "application/json",
  //       "Cache-Control": "no-cache",
  //       skip: "true",
  //     });
  //     let options = {
  //       headers: httpHeaders,
  //     };
  //     let fullUrl = "";
  //     if (status == "new") {
  //       fullUrl = this.autoSaveNewActionUrl;
  //     } else if (status == "update") {
  //       fullUrl = this.autoSaveUpdateActionUrl;
  //     } else if (status == "delete") {
  //       fullUrl = this.autoSaveDeleteActionUrl;
  //     }
  //     return this.httpClient.put<object>(fullUrl, data, options);
  //   }
  // mrr and store
  // saveSurvey(data: object): Observable<object> {
  //   let httpHeaders = new HttpHeaders({
  //     "Content-Type": "application/json",
  //     "Cache-Control": "no-cache",
  //   });
  //   let options = {
  //     headers: httpHeaders,
  //   };
  //   let fullUrl = this.saveResponseUrl;
  //   return this.httpClient.put<object>(fullUrl, data, options);
  // }
  // non use

  // eitbEmailTrigger(data: object): Observable<object> {
  //   let httpHeaders = new HttpHeaders({
  //     "Content-Type": "application/json",
  //     "Cache-Control": "no-cache",
  //   });
  //   let options = {
  //     headers: httpHeaders,
  //   };
  //   let fullUrl = this.eitbEmailTriggerUrl;
  //   return this.httpClient.post<object>(fullUrl, data, options);
  // }

  // mrrActivateEmailTrigger(data: object): Observable<object> {
  //   let httpHeaders = new HttpHeaders({
  //     "Content-Type": "application/json",
  //     "Cache-Control": "no-cache",
  //   });
  //   let options = {
  //     headers: httpHeaders,
  //   };
  //   let fullUrl = this.mrrActivateEmailTriggerUrl;
  //   return this.httpClient.post<object>(fullUrl, data, options);
  // }
  // mrr,landing,suvey
  // createSurvey(data: object): Observable<object> {
  //   let httpHeaders = new HttpHeaders({
  //     "Content-Type": "application/json",
  //     "Cache-Control": "no-cache",
  //   });
  //   let options = {
  //     headers: httpHeaders,
  //   };
  //   let fullUrl = this.createSurveyUrl;
  //   return this.httpClient.post<object>(fullUrl, data, options);
  // }
  // mrr survey
  // createAction(data: object): Observable<object> {
  //   let httpHeaders = new HttpHeaders({
  //     "Content-Type": "application/json",
  //     "Cache-Control": "no-cache",
  //   });
  //   let options = {
  //     headers: httpHeaders,
  //   };
  //   let fullUrl = this.createActionUrl;
  //   return this.httpClient.post<object>(fullUrl, data, options);
  // }
  // all
  // updateSurvey(data: object): Observable<object> {
  //   let httpHeaders = new HttpHeaders({
  //     "Content-Type": "application/json",
  //     "Cache-Control": "no-cache",
  //   });
  //   let options = {
  //     headers: httpHeaders,
  //   };
  //   let fullUrl = this.updateSurveyUrl;
  //   return this.httpClient.post<object>(fullUrl, data, options);
  // }
  // mrr survey
  // updateAction(updateParams: string, data: object): Observable<object> {
  //     let httpHeaders = new HttpHeaders({
  //       "Content-Type": "application/json",
  //       "Cache-Control": "no-cache",
  //     });
  //     let options = {
  //       headers: httpHeaders,
  //     };
  //     let fullUrl = this.updateActionUrl + updateParams;
  //     return this.httpClient.put<object>(fullUrl, data, options);
  //   }
  // all
  // uploadPhoto(
  //   data: Blob,
  //   surveyID: string,
  //   questionID: string,
  //   storeID: string,
  //   fileSize: string
  // ): Observable<object> {
  //   let fullUrl = this.uploadPhotosUrl;
  //   const formData: FormData = new FormData();
  //   formData.append("file1", data, this.removeSpecialCharactersFromFileName(data["name"]));
  //   formData.append("surveyID", surveyID);
  //   formData.append("questionID", questionID);
  //   formData.append("storeID", storeID);
  //   formData.append("fileSize", fileSize);
  //   return this.httpClient.post<object>(fullUrl, formData);
  // }

  // uploadDocs(
  //   data: Blob,
  //   surveyID: string,
  //   fileTag: string,
  //   storeID: string,
  //   fileSize: string
  // ): Observable<object> {
  //   let fullUrl = this.uploadPhotosUrl;
  //   const formData: FormData = new FormData();
  //   formData.append("file1", data, this.removeSpecialCharactersFromFileName(data["name"]));
  //   formData.append("surveyID", surveyID);
  //   formData.append("fileTag", fileTag);
  //   formData.append("storeID", storeID);
  //   formData.append("fileSize", fileSize);
  //   return this.httpClient.post<object>(fullUrl, formData);
  // }

  // header , supoort land

  // getRoles(): Observable<object> {
  //   return this.httpClient.get<object>(this.rolesUrl, this.httpOptions).pipe(
  //     catchError((err) => {
  //       console.log("Error in getting roles data.");
  //       console.log(err.message);
  //       return of(null);
  //     })
  //   );
  // }
  // auth 
  getUser(): Observable<User> {
    return this.httpClient.get<any>(this.userUrl, this.httpOptions).pipe(
      catchError((err) => {
        console.log('Error in getting user data.');
        console.log(err.message);
        return of(null);
      }),
      map((user) => {
        // append the support email field
        user['supportEmail'] = this.getSupportEmailForDivision(user['division']);

        // our application expects user.super to be true if the role is Super
        // TODO: this should probably come from the backend or we should change how our app considers these roles
        if (user.role === 'Super') {
          user.super = true;
        } else if (user['role'] == 'NationalView') {
          user['super'] = true;
        }

        if (environment['useMockData'] && !user['featureSubfeatureKeys']) {
          user['featureSubfeatureKeys'] = mockUserFeatures;
        }

        return user;
      })
    );
  }

  /**
   * Support emails change depending on which division the user is from. Returns the support email for any given division.
   *
   * @param division - The division from the user object.
   * @returns The support email for the supplied division.
   */
  private getSupportEmailForDivision(division: User['division']): string {
    let supportEmail: string = 'https://woolworths.my/medallia'; // default support portal

    switch (division) {
      case 'Woolworths NZ Stores': case 'Woolworths NZ eStores': case 'Woolworths NZ Primary Connect':
        supportEmail = 'iverifyfeedback@woolworths.co.nz';
        break;
      case 'CFC':
        supportEmail = 'jnicolas@woolworths.com.au';
        break;
      case 'Metro':
        supportEmail = 'metrobusinessimprovement@woolworths.com.au';
        break;
      case 'DC':
        supportEmail = 'iverifyfeedbackDC@woolworths.com.au';
        break;
      case 'Bigw':
        supportEmail = 'compliance@bigw.com';
        break;
    }

    return supportEmail;
  }

  // non use
  getTest401(): Observable<object> {
    return this.httpClient.get<object>(this.test401Url, this.httpOptions).pipe(
      catchError((err) => {
        console.log('Error in getTest401.');
        console.log(err.message);
        return of(null);
      })
    );
  }
  // mrr,landing,survey
  // createOnTheFlySurvey(data: object): Observable<object> {
  //   let httpHeaders = new HttpHeaders({
  //     "Content-Type": "application/json",
  //     "Cache-Control": "no-cache",
  //   });
  //   let options = {
  //     headers: httpHeaders,
  //   };
  //   let fullUrl = this.createOnTheFlySurveyUrl;
  //   return this.httpClient.post<object>(fullUrl, data, options);
  // }

  // survey acces management
  // getUserAccessList(
  //   index: string,
  //   count: string,
  //   query: string
  //   // status: string
  // ): Observable<object[]> {
  //   let httpHeaders = new HttpHeaders({
  //     skip: "true"
  //   });
  //   let options = {
  //     headers: httpHeaders,
  //   };
  //   let fullUrl =
  //     this.getUserAccessListUrl +
  //     index +
  //     count +
  //     query;
  //   return this.httpClient.get<object[]>(fullUrl, options);
  // }

  // survey acces management

  // updateUser(type: string, data: object): Observable<object> {
  //   let httpHeaders = new HttpHeaders({
  //     "Content-Type": "application/json",
  //     "Cache-Control": "no-cache",
  //   });
  //   let options = {
  //     headers: httpHeaders,
  //   };
  //   let fullUrl = this.updateUserUrl + type;
  //   return this.httpClient.post<object>(fullUrl, data, options);
  // }

  // MRR Assessment , landing page
  // getmrrSurveys(
  //   userGrade: string,
  //   group: string,
  //   status: string,
  //   index: string,
  //   count: string,
  //   type: string,
  //   filterName: string
  // ): Observable<object[]> {
  //   let fullUrl =
  //     this.surveysUrl +
  //     userGrade +
  //     index +
  //     count +
  //     "?status=" +
  //     status +
  //     "&division=" +
  //     group +
  //     "&type=" +
  //     type + "&filterName=" + filterName;
  //   return this.httpClient.get<object[]>(fullUrl);
  // }

  // getOnTheFlySurveytemplatesMRR(
  //   userGrade: string,
  //   group: string,
  //   status: string,
  //   index: string,
  //   count: string,
  //   type: string,
  //   filterName: string
  // ): Observable<object[]> {
  //   let fullUrl =
  //     this.surveysUrl +
  //     userGrade +
  //     index +
  //     count +
  //     "?status=" +
  //     status +
  //     "&division=" +
  //     group +
  //     "&type=" +
  //     type + "&filterName=" + filterName;;
  //   return this.httpClient.get<object[]>(fullUrl);
  // }

  // MRR Assessment
  // searchSectionHeadings(surveyTitle, sectionHeading, filter): Observable<object> {
  //   if (surveyTitle) {
  //     filter += "&surveyTitle=" + surveyTitle;
  //   }
  //   if (sectionHeading) {
  //     filter += "&sectionHeading=" + sectionHeading;
  //   }
  //   let fullUrl = this.searchSectionHeadingsUrl + filter;
  //   return this.httpClient.get<object>(fullUrl);
  // }

  // searchControlNames(surveyTitle, sectionHeading, controlName, filter): Observable<object> {
  //   if (surveyTitle) {
  //     filter += "&surveyTitle=" + surveyTitle;
  //   }
  //   if (sectionHeading) {
  //     filter += "&sectionHeading=" + sectionHeading;
  //   }
  //   if (controlName) {
  //     filter += "&controlName=" + controlName;
  //   }
  //   console.log(filter)
  //   let fullUrl = this.searchControlNamesUrl + filter;
  //   return this.httpClient.get<object>(fullUrl);
  // }

  // getEvidenceList(surveyTitle, sectionHeading, controlName, storeID, type, filter): Observable<object> {
  //   if (surveyTitle) {
  //     filter += "&surveyTitle=" + surveyTitle;
  //   }
  //   if (sectionHeading) {
  //     filter += "&sectionHeading=" + sectionHeading;
  //   }
  //   if (controlName) {
  //     filter += "&controlName=" + controlName;
  //   }
  //   if (storeID) {
  //     filter += "&storeID=" + storeID;
  //   }
  //   if (type) {
  //     filter += "&type=" + type;
  //   }
  //   console.log(filter)
  //   let fullUrl = this.listEvidenceUrl + filter;
  //   return this.httpClient.get<object>(fullUrl);
  // }

  // createMRRFinalReport(data: object): Observable<object> {
  //   let httpHeaders = new HttpHeaders({
  //     "Content-Type": "application/json",
  //     "Cache-Control": "no-cache",
  //   });
  //   let options = {
  //     headers: httpHeaders,
  //   };
  //   let fullUrl = this.updateMRRFinalReport;
  //   return this.httpClient.post<object>(fullUrl, data, options);
  // }

  // SignOff(data: object): Observable<object> {
  //   let httpHeaders = new HttpHeaders({
  //     "Content-Type": "application/json",
  //     "Cache-Control": "no-cache",
  //   });
  //   let options = {
  //     headers: httpHeaders,
  //   };
  //   let fullUrl = this.signOff;
  //   return this.httpClient.post<object>(fullUrl, data, options);
  // }

  /**
   * Our backend security policies reject file names that have special characters, probably because of a false flag.
   * This function removes special characters (except for hyphen, period and space) to allow them to upload successfully.
   * 
   * @param fileName - The file name we want to remove special characters from.
   * @returns The passed in file name, with all special characters removed.
   */
  private removeSpecialCharactersFromFileName(fileName: string): string {
    return fileName.replace(/[^\w .-]/g, '');
  }
}
