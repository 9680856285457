import { convertToCamelCase } from '../convert-to-camel-case/convert-to-camel-case';

/**
 * Converts the given input string into a valid key name.
 * If the input string is non-empty, the resulting key name is in camel case;
 * otherwise, it defaults to a dash.
 *
 * @param {string} value - The string to be converted into a key name.
 * @returns {string} The key name derived from the input string.
 */
export function convertToKeyName(value: string) {
  const key = convertToCamelCase(value);
  return key !== '' ? key : '-';
}
