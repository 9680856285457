/**
 * Converts the input string into camel case.
 * 
 * @param {string} value - String value to convert.
 */
export function convertToCamelCase(value: string) {
  return value
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}
