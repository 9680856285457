import {
  Component, EventEmitter, HostBinding, Input, OnInit, Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Dictionary } from 'src/app/types/dictionary';
import { MatDialog } from '@angular/material/dialog';
import { SortPipe } from 'src/app/custom-pipes/sort.pipe';
import { showErrorDialog } from 'src/app/utils/show-error-dialog/show-error-dialog';
import { isDictionaryEmpty } from 'src/app/utils/is-dictionary-empty/is-dictionary-empty';
import { TitleCasePipe } from '@angular/common';
import { clearSelectedLocation } from '../../utils/user-roles/clear-selected-location';
import { UserService } from '../../common-services/user.service';
import { createLevelQuery } from '../../utils/user-roles/create-level-query';
import { setupOrgLocations } from '../../utils/user-roles/setup-org-locations';
import { getOrgPropertyName } from '../../utils/user-roles/get-org-property-name';
import { resetLocationsFromLevel } from '../../utils/user-roles/reset-locations-from-level';
import { copyDictionary } from '../../utils/user-roles/copy-dictionary';

@Component({
  selector: 'app-location-selection',
  templateUrl: './location-selection.component.html',
  styleUrls: ['./location-selection.component.scss']
})
export class LocationSelectionComponent implements OnInit {
  @Input() form: FormGroup;

  @Input() set roleLevel(value: string) {
    if ((value ?? '') !== (this._roleLevel ?? '')) {
      // Reset location form controls based on the selected role level
      this.assignRoleLevel(value);
      this.removeLocationFormControls();
      this.resetLocationFilters();
      this.createLocationFormControls('division', this.roleLevel);
      this.refreshLocations('', 'division');
    }
  }

  get roleLevel(): string {
    return this._roleLevel;
  }

  // Changes to selected locations should be responsible for displaying the location form controls
  // These should be considered as initial values.
  @Input() set selectedLocations(value: Dictionary<string, string>) {
    // remove empty locations
    for (const locationKey in value) {
      if (!value[locationKey]) {
        delete value[locationKey];
      }
    }

    this.removeLocationFormControls();
    this.resetLocationFilters();
    this.createLocationFormControls('division', this.roleLevel);
    this._selectedLocations = value;
    if (!isDictionaryEmpty(value)) {
      // Copy role level & selected locations as original values for restoration on reset
      this._originalRoleLevel = this.roleLevel;
      this.originalSelectedLocations = copyDictionary(this._selectedLocations);
      this.initialiseLocations();
    }
  }

  get selectedLocations(): Dictionary<string, string> {
    return this._selectedLocations;
  }

  @Input() userOrgStructure: object = {};

  @Input() division: string;

  @Input() showLabels = true;

  @Input() showAllOption = false;

  @Output() selectedLocation = new EventEmitter<Dictionary<string, string>>();

  @Output() selectedLocationsChanged = new EventEmitter<Dictionary<string, string>>();

  locationsOrdered: string[] = [];

  _selectedLocations: Dictionary<string, string> = {};

  isLocationLoading: Dictionary<string, boolean> = {};

  availableLocations: Dictionary<string, any> = {};

  showLocations: Dictionary<string, boolean> = {};

  @HostBinding('class.hidden') isHidden = true;

  lastLevel: string;

  originalSelectedLocations: Dictionary<string, string> = {};

  originalShowLocations: Dictionary<string, boolean> = {};

  private _roleLevel: string;

  private _originalRoleLevel: string;

  readonly locationSelectionErrorTitle = 'Location selection error';

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private sortPipe: SortPipe,
    private titleCasePipe: TitleCasePipe,
    private fb: FormBuilder
  ) { }

  /**
   * Initializes organizational locations, sets up initial values, and prepares the component for use.
   */
  ngOnInit(): void {
    if (!this.form) {
      this.form = new FormGroup({});
    }
    // Setup organizational locations based on the organizational structure.
    // Do not initialise passed in selected locations.
    // HACK! Add store node to org structure to support roles with store entity types.
    this.addStoreOrgNode();
    setupOrgLocations(
      this.userOrgStructure,
      {},
      this.showLocations,
      this.locationsOrdered
    );
  }

  assignRoleLevel(value: string) {
    this._roleLevel = value;
    this.lastLevel = getOrgPropertyName(this.roleLevel || 'division');
  }

  addStoreOrgNode() {
    const storeNode = this.userOrgStructure['storeID'];
    if (!storeNode) {
      this.userOrgStructure['storeID'] = 'next';
    }
  }

  /**
   * Initializes locations and related functionalities.
   * Creates form controls, initializes their visibility, refreshes styles, loads initial location data,
   * and stores the original visibility state for reference.
   */
  initialiseLocations() {
    this.lastLevel = getOrgPropertyName(this.roleLevel || 'division');

    // Create form controls for the top division level up to the role level
    this.createLocationFormControls('division', this.roleLevel);

    // Initialize the visibility of location elements based on the organizational structure
    this.initialiseShownLocations();

    this.updateVisibility();

    this.loadNextLocationDataForLevel('division');

    // Store the original visibility state of location elements
    this.originalShowLocations = copyDictionary(this.showLocations);
  }

  /**
   * Hide the component when there aren't any locations to show.
   */
  private updateVisibility() {
    const locationsToShowCount = Object.values(this.showLocations).filter((value) => value === true).length;

    if (locationsToShowCount === 0) {
      this.isHidden = true;
    } else {
      this.isHidden = false;
    }
  }

  /**
   * Resets the component's state to its original values.
   * Restores the original role level, last level, selected locations, and location visibility settings.
   * Recreates location form controls, initializes locations, and refreshes related functionalities.
   */
  reset() {
    this.assignRoleLevel(this._originalRoleLevel);
    this._selectedLocations = copyDictionary(this.originalSelectedLocations);
    this.showLocations = copyDictionary(this.originalShowLocations);
    this.initialiseLocations();
  }

  /**
   * Event handler for selecting a location.
   * Emits the selected location's key-value pair and triggers the refresh of dependent locations.
   *
   * @param {Event} event - The event object representing the location selection.
   * @param {string} level - The level of the selected location.
   */
  onSelectLocation(event, level) {
    // Retrieve the selected location value
    const selectedLocation = event.target.value;

    // Emit the selected location's key-value pair
    this.selectedLocation.emit({ key: level, value: selectedLocation });

    // Trigger the refresh of dependent locations based on the selected location
    this.refreshLocations(selectedLocation, level);

    // Emit the selected locations
    this.selectedLocationsChanged.emit(this.selectedLocations);
  }

  /**
   * Maps an organization node value to its corresponding (user friendly) location key name.
   *
   * @param {string} organizationNode - The organization node value to be mapped.
   * @returns {string} The corresponding location key name.
   */
  locationKey(organizationNode: string): string {
    switch (organizationNode) {
      case 'groupID':
        return 'group';
      case 'storeID':
        return 'store';
      default:
        return organizationNode;
    }
  }

  /**
   * Removes form controls associated with each location.
   * Iterates through the ordered list of locations. For each location, it checks if a form control exists.
   * If a form control exists for the location, it removes the control from the form.
   */
  removeLocationFormControls() {
    this.locationsOrdered.forEach((key: string) => {
      const controlName = this.locationKey(key);
      if (this.form.get(controlName)) {
        this.form.removeControl(controlName);
      }
    });
  }

  /**
   * Resets all location filters and selections.
   * Clears the selected locations and hides all location elements.
   */
  resetLocationFilters(): void {
    Object.keys(this._selectedLocations).forEach((key) => clearSelectedLocation(key, this._selectedLocations));
    Object.keys(this.showLocations).forEach((key) => this.showLocations[key] = false);
  }

  /**
   * Creates form controls for each location level based on the organizational structure.
   *
   * @param {string} fromLevel - The starting level from which to create form controls.
   * @param {string} lastLevel - The last level until which form controls should be created.
   */
  createLocationFormControls(fromLevel: string, lastLevel: string) {
    // If lastLevel is not specified or it's 'global', return
    if (!lastLevel || lastLevel.toLowerCase() === 'global') {
      return;
    }
    // Get the next level based on the organizational structure
    const level = this.userOrgStructure[fromLevel];

    // If the next level is not defined in the organizational structure, return
    if (!this.userOrgStructure[level]) {
      return;
    }

    // Get the control name for the current level
    const controlName = this.locationKey(level);

    // If the form control doesn't exist, add it to the form with required validation
    if (!this.form.get(controlName)) {
      this.form.addControl(controlName, this.fb.control('', Validators.required));
    }

    // If the last level matches the current level, return
    if (lastLevel.toLowerCase() === this.locationKey(level).toLowerCase()) {
      return;
    }

    // Recursively call createLocationFormControls for the next level
    this.createLocationFormControls(level, lastLevel);
  }

  /**
   * Refreshes the location level with the selected location.
   *
   * @param {string} selectedLocation - The selected location value.
   * @param {string} locationLevel - The current location level of the selection.
   */
  refreshLocations(selectedLocation, locationLevel): void {
    // Determine the next level in the organizational structure
    const nextLevel = this.userOrgStructure[locationLevel];

    // Set loading state for the next level
    this.isLocationLoading[nextLevel] = true;

    // Update the selected location for the current level
    if (locationLevel !== 'division') {
      this._selectedLocations[locationLevel] = selectedLocation;
    }

    // Reset locations from the next level onwards based on the current selection and visibility settings
    resetLocationsFromLevel(this.userOrgStructure, nextLevel, this.roleLevel, this._selectedLocations, this.showLocations);

    this.updateVisibility();
    this.refreshLocationValues();
    
    // Do not show or populate next level selections when All option is selected.
    // Note: Division is the top level and does not contain a selection.
    // We want want to populate the next level selection options.
    if (locationLevel !== 'division' && selectedLocation === '') { // All option
      this.showLocations[nextLevel] = false;
      return;
    }

    // Check if there is a next level in the organizational structure
    if (this.userOrgStructure[nextLevel]) {
      // Disable the form control for the next level while loading
      this.disableLocation(nextLevel, true);

      // Fetch data for the next level
      this.userService
        .getNextLevel(createLevelQuery(this.userOrgStructure, nextLevel, this.division, this._selectedLocations))
        .subscribe({
          next: (data) => {
            // Update available locations for the next level and set loading state to false
            this.availableLocations[nextLevel] = this.getAvailableLocations(this.sortPipe.transform(data[nextLevel]), nextLevel, this.showAllOption);
            this.isLocationLoading[nextLevel] = false;
            // Enable form control for the next location level
            this.disableLocation(nextLevel, false);
            const controlName = this.locationKey(nextLevel);
            if (this.form.get(controlName)) {
              this.form.controls[controlName].setValue('');
            }
          },
          error: (error) => {
            // Handle errors by setting loading state to false and displaying error dialog
            this.isLocationLoading[nextLevel] = false;
            this.disableLocation(nextLevel, false);
            showErrorDialog(this.dialog, error, this.locationSelectionErrorTitle);
          }
        });
    }
  }

  /**
   * Generates an array of location objects with a `name` and `value` property.
   * Optionally prepends an "All {Level}s" option at the beginning of the array.
   *
   * @param {string[]} locations - An array of location names.
   * @param {string} level - The hierarchical level for the "All {Level}s" option (e.g., 'region', 'city').
   * @param {boolean} showAllOption - A flag indicating whether to include an "All {Level}s" option at the beginning of the array.
   * @returns {object[]} An array of objects, each with a `name` and `value` property. If `showAllOption` is true, the array will include an "All {Level}s" option at the beginning with `value` set to empty string value.
   */
  getAvailableLocations(locations: string[], level: string, showAllOption): object[] {
    const output = locations?.map((item) => ({
      name: item,
      value: item
    }));
    if (showAllOption) {
      output.unshift({ name: `All ${this.titleCasePipe.transform(this.locationKey(level))}s`, value: '' });
    }
    return output;
  }

  /**
   * Initializes the visibility of location elements based on the organizational structure.
   * Iterates through the organizational structure starting from the 'division' level,
   * setting visibility flags for each location level. 
   * Also, handles loading states and disables controls accordingly.
   */
  initialiseShownLocations(): void {
    let key = this.userOrgStructure['division'];
    let show = true;
    while (this.userOrgStructure[key]) {
      if (this.lastLevel == 'division') {
        this.showLocations[key] = false;
      } else {
        this.showLocations[key] = show;
        if (show) {
          this.isLocationLoading[key] = true;
          this.disableLocation(key, true);
        }
      }
      if (key == this.lastLevel) {
        show = false;
      }
      key = this.userOrgStructure[key];
    }
  }

  /**
   * Recursively loads a chain of locations starting from a parent location level.
   * It loads location data for the next location. Calling itself once loaded/completed
   * with the current location level thus being the parent location level for the next call.
   *
   * @param {string} parentLocationLevel - The parent location level from which to load the chain of locations.
   */
  loadNextLocationDataForLevel(parentLocationLevel: string): void {
    // Determine the next level in the organizational structure
    const nextLevel = this.userOrgStructure[parentLocationLevel];

    // Check if there is a next level, it's set to be shown, and a role level is defined
    if (
      this.userOrgStructure[nextLevel]
      && this.showLocations[nextLevel]
      && this.roleLevel
    ) {
      // Set loading state for the next level and disable its form control
      this.isLocationLoading[nextLevel] = true;
      this.disableLocation(nextLevel, true);

      // Fetch location data for the next level
      this.userService
        .getNextLevel(createLevelQuery(this.userOrgStructure, nextLevel, this.division, this._selectedLocations))
        .subscribe(
          (data) => {
            // Process retrieved data if available
            if (data) {
              // Update loading state to false and store available locations
              this.isLocationLoading[nextLevel] = false;
              this.availableLocations[nextLevel] = this.getAvailableLocations(this.sortPipe.transform(data[nextLevel]), nextLevel, false);

              // Update form control value and selected location value
              const locationValue = this._selectedLocations[nextLevel];
              this.form.controls[this.locationKey(nextLevel)]?.setValue(locationValue);
              this._selectedLocations[nextLevel] = locationValue;

              // Re-enable the form control for the loaded next level
              this.disableLocation(nextLevel, false);

              // Recursively load location data for subsequent levels if applicable
              if (nextLevel != this.lastLevel) {
                this.loadNextLocationDataForLevel(nextLevel);
              }
            }
          },
          (error) => {
            // Handle errors by updating loading state to false and displaying error dialog
            this.isLocationLoading[nextLevel] = false;
            this.disableLocation(nextLevel, false);
            showErrorDialog(this.dialog, error, this.locationSelectionErrorTitle);
          }
        );
    }
  }

  /**
   * Refreshes the values of location controls defined by locationsOrdered in the form.
   * Iterates through the ordered list of selected locations. If the value of a location is empty,
   * it resets the corresponding form control's value to an empty string.
   */
  refreshLocationValues() {
    this.locationsOrdered.forEach((key: string) => {
      if (this._selectedLocations[key] === '') {
        const controlName = this.locationKey(key);
        if (this.form.get(controlName)) {
          this.form.controls[controlName].setValue('');
        }
      }
    });
  }

  /**
   * Enables or disables a location form control based on the provided organization node and disable flag.
   *
   * @param {string} organizationNode - The organization node representing the control to be disabled/enabled.
   * @param {boolean} disable - A boolean flag indicating whether to disable (true) or enable (false) the control.
   */
  disableLocation(organizationNode: string, disable: boolean) {
    const controlName = this.locationKey(organizationNode);
    if (!this.form.get(controlName)) {
      return;
    }
    if (disable) {
      this.form.controls[controlName].disable();
    } else {
      this.form.controls[controlName].enable();
    }
  }
}
